import React, { useState, useEffect, useCallback } from 'react';
import { FaEye, FaChevronDown, FaTrash } from 'react-icons/fa';
import { observer, inject } from 'mobx-react';

// Simple Alert Component
const SimpleAlert = ({ message, type = 'error' }) => {
  if (!message) return null;
  
  const bgColor = type === 'error' ? 'bg-red-100' : 'bg-green-100';
  const textColor = type === 'error' ? 'text-red-700' : 'text-green-700';
  const borderColor = type === 'error' ? 'border-red-400' : 'border-green-400';

  return (
    <div className={`${bgColor} border ${borderColor} ${textColor} px-4 py-3 rounded relative mb-4`} role="alert">
      <span className="block sm:inline">{message}</span>
    </div>
  );
};

const InterviewResultModal = ({ interview, onClose }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center z-50 p-4 overflow-y-auto">
      <div className="bg-white rounded-lg shadow-xl p-4 sm:p-6 w-full max-w-2xl mt-16 mb-8 max-h-[calc(100vh-8rem)] overflow-y-auto">
        <h2 className="text-xl sm:text-2xl font-semibold mb-4">{interview.candidateName} - {interview.jobTitle}</h2>
        <div className="space-y-4">
          <div>
            <p className="font-medium">Interview Date: <span className="font-normal">{interview.date}</span></p>
            <p className="font-medium">Overall Result: <span className={`font-normal ${interview.evaluation.isSuitable ? 'text-green-600' : 'text-red-600'}`}>
              {interview.evaluation.isSuitable ? 'Suitable' : 'Not Suitable'}
            </span></p>
            <p className="font-medium">Overall Score: <span className="font-normal">{interview.evaluation.overallScore}/10</span></p>
          </div>
          <div>
            <h3 className="text-lg font-medium mb-2">Evaluation</h3>
            <p className="text-gray-700">{interview.evaluation.explanation}</p>
          </div>
          <div>
            <h3 className="text-lg font-medium mb-2">Strengths</h3>
            <ul className="list-disc list-inside">
              {interview.evaluation.strengths.map((strength, index) => (
                <li key={index} className="text-gray-700">{strength}</li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-medium mb-2">Areas for Improvement</h3>
            <ul className="list-disc list-inside">
              {interview.evaluation.areasForImprovement.map((area, index) => (
                <li key={index} className="text-gray-700">{area}</li>
              ))}
            </ul>
          </div>
        </div>
        <button
          onClick={onClose}
          className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded transition-colors duration-300 mt-6"
        >
          Close
        </button>
      </div>
    </div>
  );
};

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full">
        <h3 className="text-lg font-semibold mb-4">Confirm Deletion</h3>
        <p className="text-gray-600 mb-6">Are you sure you want to delete this interview record?</p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 border rounded hover:bg-gray-100"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

const CompletedInterviews = inject('store')(observer(({ store }) => {
  const [selectedJob, setSelectedJob] = useState('');
  const [interviews, setInterviews] = useState([]);
  const [selectedInterview, setSelectedInterview] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [interviewToDelete, setInterviewToDelete] = useState(null);

  const refreshJobList = useCallback(async () => {
    try {
      setIsLoading(true);
      let response = await store.api.post('/user/JobDetails/view');
      store.joblist = response.data.map((job) => ({
        jobid: job.jobId,
        jobname: job.jobname,
        jobdescription: job.jobdescription,
      }));
    } catch (error) {
      console.error("Failed to fetch job list:", error);
      setError("Failed to fetch job list. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [store]);

  const fetchPendingInterviews = async () => {
    try {
      setIsLoading(true);
      setError('');
      const response = await store.api.get('/user/completed');
      setInterviews(response.data);
    } catch (error) {
      setError('Error fetching completed interviews. Please try again.');
      console.error('Error fetching completed interviews:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    refreshJobList();
    fetchPendingInterviews();
  }, [refreshJobList]);

  const handleJobChange = (e) => {
    setSelectedJob(e.target.value);
  };

  const handleViewResult = async (interviewId) => {
    try {
      const response = await store.api.get(`/user/completed-interviews/${interviewId}`);
      setSelectedInterview(response.data);
      setIsModalOpen(true);
    } catch (error) {
      setError('Error fetching interview details. Please try again.');
      console.error('Error fetching interview details:', error);
    }
  };

  const handleDeleteClick = (interviewid) => {
    setInterviewToDelete(interviewid);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      setIsLoading(true);
      await store.api.delete(`/user/completed-interviews/${interviewToDelete}`);
      setInterviews(interviews.filter(interview => interview.id !== interviewToDelete));
      setDeleteModalOpen(false);
      setInterviewToDelete(null);
    } catch (error) {
      setError('Failed to delete interview. Please try again.');
      console.error('Error deleting interview:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredInterviews = selectedJob
    ? interviews.filter(interview => interview.jobId === parseInt(selectedJob))
    : interviews;

  return (
    <div className="container mx-auto px-4 py-8">
      {error && <SimpleAlert message={error} />}
      
      <div className="mb-6">
        <label htmlFor="jobSelect" className="block text-sm font-medium text-gray-700 mb-2">
          Select Job
        </label>
        <div className="relative">
          <select
            id="jobSelect"
            value={selectedJob}
            onChange={handleJobChange}
            className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option value="">All Jobs</option>
            {store.joblist.map(job => (
              <option key={job.jobid} value={job.jobid}>{job.jobname}</option>
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <FaChevronDown />
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="text-center py-4">Loading...</div>
      ) : (
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Candidate
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Job Title
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Date
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Result
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Score
                </th>
                
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredInterviews.map(interview => (
                <tr key={interview.id}>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">{interview.candidateName}</p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">{interview.jobTitle}</p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">{interview.date}</p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <span className={`relative inline-block px-3 py-1 font-semibold ${
                      interview.result === 'Suitable' ? 'text-green-900' : 'text-red-900'
                    } leading-tight`}>
                      <span aria-hidden className={`absolute inset-0 ${
                        interview.result === 'Suitable' ? 'bg-green-200' : 'bg-red-200'
                      } opacity-50 rounded-full`}></span>
                      <span className="relative">{interview.result}</span>
                    </span>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">{interview.overallScore}/10</p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <div className="flex space-x-2">
                      <button
                        onClick={() => handleViewResult(interview.id)}
                        className="text-blue-600 hover:text-blue-900"
                      >
                        <FaEye className="inline-block mr-1" /> View 
                      </button>
                      <button
                        onClick={() => handleDeleteClick(interview.id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        <FaTrash className="inline-block mr-1" /> Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {isModalOpen && selectedInterview && (
        <InterviewResultModal
          interview={selectedInterview}
          onClose={() => setIsModalOpen(false)}
        />
      )}

      <DeleteConfirmationModal
        isOpen={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setInterviewToDelete(null);
        }}
        onConfirm={handleDeleteConfirm}
      />
    </div>
  );
}));

export default CompletedInterviews;