import React from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../Layout/Layout';
import VirtualInterviewRoom from './Virtual-Interview/VirtualInterviewRoom';

const VirtualInterviewRoomPage = () => {
  const { interviewLink } = useParams();

  return (
   // <Layout>
      <VirtualInterviewRoom interviewLink={interviewLink} />
   // </Layout>
  );
};

export default VirtualInterviewRoomPage;