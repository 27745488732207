import { ThemeProvider } from 'styled-components';
import React, { Component } from 'react';
import { Provider } from 'mobx-react';
import { observer } from 'mobx-react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import colors from 'tailwindcss/colors';

// Components
import AccountActivation from './Components/Pages/AccountActivation';
import AppStore from './store';
import Screening from './Screening';
import Login from './Login/Login';
import CandidateLogin from './Login/CandidateLogin';
import Layout from './Core/Layout';
import Profile from './Profile/';
import LoginSuccess from './Login/Success';
import CandidateDetails from '../src/Components/CandidateDetails';
import ManageJob from '../src/Components/ManageJob';
import Assesment from '../src/Components/Pages/Assessment';
import WorkFlowGuidePage from '../src/Components/Pages/WorkFlowGuidePage';
import VirtualInterviewWorkflowPage from '../src/Components/Pages/VirtualInterviewWorkflowPage';
import InterviewSchedulingPage from '../src/Components/Pages/Virtual-Interview/InterviewSchedulingPage';
import VirtualInterviewRoomPage from '../src/Components/Pages/VirtualInterviewRoomPage';
import ManageJobPage from '../src/Components/Pages/ManageJobPage';
import Signup from './Login/Signup';

// Styles
import 'primereact/resources/themes/md-light-indigo/theme.css';
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import '../src/Components/Styles/style.css';

// Initialize store if not exists
if (!window.store) {
  window.store = new AppStore();
}

// Protected Route Component
const ProtectedRoute = ({ component: Component, allowedTypes = [], ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const { isLoggedIn, profile } = window.store;
      
      if (!isLoggedIn) {
        localStorage.setItem('returnUrl', props.location.pathname);
        return <Redirect to="/login" />;
      }

      if (allowedTypes.length && !allowedTypes.includes(profile.accountType)) {
        return (
          <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg shadow-xl p-8 max-w-md w-full">
              <h2 className="text-2xl font-bold text-red-600 mb-4">Access Denied</h2>
              <p className="text-gray-600 mb-4">
                You don't have permission to access this page.
              </p>
              <button
                onClick={() => window.history.back()}
                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
              >
                Go Back
              </button>
            </div>
          </div>
        );
      }

      return <Component {...props} />;
    }}
  />
);

// Interview Route Component
const InterviewRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const { isLoggedIn, profile } = window.store;
      const interviewLink = props.match.params.interviewLink;

      if (!isLoggedIn) {
        // If not logged in, redirect to candidate login with interview link
        sessionStorage.setItem('currentInterviewLink', interviewLink);
        return <CandidateLogin {...props} />;
      }

      if (profile.accountType === 'candidate') {
        // Candidate is logged in, show interview room
        return <Component {...props} />;
      }

      // Non-candidate users trying to access interview room
      return (
        <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl p-8 max-w-md w-full">
            <h2 className="text-2xl font-bold text-red-600 mb-4">Invalid Access</h2>
            <p className="text-gray-600 mb-4">
              This link is only for interview candidates.
            </p>
          </div>
        </div>
      );
    }}
  />
);

@observer
class App extends Component {
  render() {
    const { isLoggedIn, profile, redirect } = window.store;

    return (
      <ThemeProvider theme={colors}>
        <Provider store={window.store}>
          <Router>
            {redirect ? <Redirect to={redirect} /> : null}
            
            <Switch>
              {/* Public Routes */}
              <Route 
                exact 
                path="/login" 
                render={(props) => {
                  // If there's an interview link, use CandidateLogin
                  const hasInterviewLink = sessionStorage.getItem('currentInterviewLink');
                  if (hasInterviewLink) {
                    return <CandidateLogin {...props} />;
                  }
                  // Otherwise use regular login
                  return <Login {...props} />;
                }}
              />
              <Route path="/signup" component={Login} />
              <Route path="/auth/account-activation/:token" component={AccountActivation} />
              <Route path="/genai-for-hiring" component={Layout} />

              {/* Interview Room Route */}
              <InterviewRoute
                path="/virtual-interview-room/:interviewLink"
                component={VirtualInterviewRoomPage}
              />

              {/* Protected Routes */}
              {isLoggedIn && (
                <>
                  {profile.accountType === 'candidate' ? (
                    // Candidate-only view
                    <Switch>
                      <Route 
                        path="/virtual-interview-room/:interviewLink" 
                        component={VirtualInterviewRoomPage} 
                      />
                      <Route path="*">
                        <Redirect 
                          to={`/virtual-interview-room/${sessionStorage.getItem('currentInterviewLink')}`} 
                        />
                      </Route>
                    </Switch>
                  ) : (
                    // Regular user routes
                    <Switch>
                      <ProtectedRoute 
                        path="/" 
                        exact 
                        component={WorkFlowGuidePage}
                        allowedTypes={['user', 'admin']} 
                      />
                      
                      {/* Other protected routes */}
                      <ProtectedRoute
                        path="/workflow-guide"
                        component={WorkFlowGuidePage}
                        allowedTypes={['user', 'admin']}
                      />
                      <ProtectedRoute
                        path="/virtual-interview"
                        exact
                        component={VirtualInterviewWorkflowPage}
                        allowedTypes={['user', 'admin']}
                      />
                      <ProtectedRoute
                        path="/virtual-interview/scheduling"
                        component={InterviewSchedulingPage}
                        allowedTypes={['user', 'admin']}
                      />
                      <ProtectedRoute
                        path="/manage-jobs"
                        component={ManageJobPage}
                        allowedTypes={['user', 'admin']}
                      />
                      <ProtectedRoute
                        path="/candidate-details"
                        component={CandidateDetails}
                        allowedTypes={['user', 'admin']}
                      />
                      <ProtectedRoute
                        path="/assessment"
                        component={Assesment}
                        allowedTypes={['user', 'admin']}
                      />
                      <ProtectedRoute
                        path="/screening"
                        component={Screening}
                        allowedTypes={['user', 'admin']}
                      />
                      <ProtectedRoute
                        path="/my-profile"
                        component={Profile}
                        allowedTypes={['user', 'admin']}
                      />

                      {/* Success/Failure Routes */}
                      <Route path="/signup/success" component={LoginSuccess} />
                      <Route path="/signup/failed" component={Profile} />

                      <Route path="*">
                        <Redirect to="/" />
                      </Route>
                    </Switch>
                  )}
                </>
              )}

              {/* Not logged in - redirect to landing */}
              {!isLoggedIn && (
                <Route path="*">
                  <Redirect to="/genai-for-hiring" />
                </Route>
              )}
            </Switch>
          </Router>
        </Provider>
      </ThemeProvider>
    );
  }
}

export default App;