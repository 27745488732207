// VirtualInterviewWorkflow.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaCalendarAlt, FaVideo, FaChartLine, FaStar } from 'react-icons/fa';
import { observer, inject } from 'mobx-react';
import VirtualInterviewRoom from './VirtualInterviewRoom';
import CompletedInterviews from './CompletedInterviews';
import PendingInterviews from './PendingInterviews'

// Status Badge Component
const StatusBadge = ({ status }) => {
  const statusConfig = {
    scheduled: { label: 'Scheduled', className: 'bg-blue-100 text-blue-800' },
    attended: { label: 'Pending Evaluation', className: 'bg-yellow-100 text-yellow-800' },
    evaluated: { label: 'Evaluated', className: 'bg-green-100 text-green-800' },
    missed: { label: 'Missed', className: 'bg-red-100 text-red-800' }
  };

  const config = statusConfig[status] || statusConfig.scheduled;

  return (
    <span className={`px-2 py-1 rounded-full text-xs font-medium ${config.className}`}>
      {config.label}
    </span>
  );
};

// Interview Card Component
const InterviewCard = ({ interview, onJoin, onEvaluate }) => {
  const isAttended = interview.status === 'attended';
  const canJoin = interview.status === 'scheduled';

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4 mb-4 hover:shadow-md transition-shadow duration-300">
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-base font-medium text-gray-800">{interview.jobTitle}</h3>
        <StatusBadge status={interview.status} />
      </div>
      
      <div className="space-y-2 mb-4">
        <p className="text-sm text-gray-600">
          <span className="font-medium">Candidate:</span> {interview.candidateName}
        </p>
        <p className="text-sm text-gray-600">
          <span className="font-medium">Date:</span> {interview.date} at {interview.time}
        </p>
        {interview.score && (
          <p className="text-sm text-gray-600">
            <span className="font-medium">Score:</span> {interview.score}/10
          </p>
        )}
      </div>

      <div className="flex gap-2">
        {canJoin && (
          <button
            onClick={() => onJoin(interview.id)}
            className="flex-1 bg-blue-500 hover:bg-blue-600 text-white text-sm font-medium py-2 px-3 rounded-md transition-colors duration-300"
          >
            <FaVideo className="inline-block mr-2" />
            Join Interview
          </button>
        )}
        {isAttended && (
          <button
            onClick={() => onEvaluate(interview.id)}
            className="flex-1 border border-blue-500 hover:bg-blue-50 text-blue-500 text-sm font-medium py-2 px-3 rounded-md transition-colors duration-300"
          >
            <FaStar className="inline-block mr-2" />
            Evaluate
          </button>
        )}
      </div>
    </div>
  );
};

// Interview Stats Component
const InterviewStats = ({ stats }) => {
  return (
    <div className="grid grid-cols-2 gap-4">
      {[
        { label: "Today's Interviews", value: stats.todayCount },
        { label: "Pending Evaluations", value: stats.pendingCount },
        { label: "Weekly Interviews", value: stats.weeklyCount },
        { label: "Completion Rate", value: `${stats.completionRate}%` }
      ].map(({ label, value }) => (
        <div key={label} className="bg-gray-50 rounded-lg p-3">
          <div className="text-sm text-gray-500 font-medium">{label}</div>
          <div className="text-xl font-semibold text-gray-900 mt-1">{value || 0}</div>
        </div>
      ))}
    </div>
  );
};

// Main Dashboard Component
const VirtualInterviewWorkflow = inject('store')(observer(({ store }) => {
  const [showInterviewRoom, setShowInterviewRoom] = useState(false);
  const [currentInterview, setCurrentInterview] = useState(null);
  const [interviews, setInterviews] = useState([]);
  const [activeTab, setActiveTab] = useState('scheduled');
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState({
    todayCount: 0,
    pendingCount: 0,
    weeklyCount: 0,
    completionRate: 0
  });

  useEffect(() => {
    if (activeTab == 'scheduled') {
      fetchInterviews();
    }
    fetchStats();
  }, [activeTab]);

  const fetchInterviews = async () => {
    setIsLoading(true);
    try {
      const response = await store.api.get(`/user/interviews/${activeTab}`);
      if (response.data && Array.isArray(response.data)) {
        setInterviews(response.data);
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error fetching interviews:', error);
      store.notifications?.error?.('Failed to load interviews');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchStats = async () => {
    try {
      const response = await store.api.get('/interviews/statistics');
      if (response.data) {
        setStats({
          todayCount: response.data.todayCount || 0,
          pendingCount: response.data.pendingCount || 0,
          weeklyCount: response.data.weeklyCount || 0,
          completionRate: response.data.completionRate || 0
        });
      }
    } catch (error) {
      console.error('Error fetching statistics:', error);
    }
  };

  const handleJoinInterview = (interviewId) => {
    const interview = interviews.find(i => i.id === interviewId);
    setCurrentInterview(interview);
    setShowInterviewRoom(true);
  };

  const handleEvaluate = (interviewId) => {
    store.navigate(`/evaluate-interview/${interviewId}`);
  };

  const handleExitInterviewRoom = async () => {
    if (currentInterview) {
      try {
        await store.api.patch(`/interviews/${currentInterview.id}/status`, { 
          status: 'attended' 
        });
        setShowInterviewRoom(false);
        setCurrentInterview(null);
        fetchInterviews();
        fetchStats();
      } catch (error) {
        console.error('Error updating interview status:', error);
        store.notifications?.error?.('Failed to update interview status');
      }
    }
  };

  if (showInterviewRoom && currentInterview) {
    return (
      <VirtualInterviewRoom 
        interviewDetails={currentInterview}
        onExit={handleExitInterviewRoom}
      />
    );
  }

  const tabs = [
    { id: 'scheduled', label: 'Scheduled Interviews' },
    { id: 'pending', label: 'Pending Evaluation' },
    { id: 'completed', label: 'Completed' }
  ];

  const renderContent = () => {
    if (activeTab === 'completed') {
      return <CompletedInterviews />;
    }

    else if (activeTab === 'pending') {
      return <PendingInterviews />;
    }

    return (
      <>
        {isLoading ? (
          <p className="text-center py-4 text-gray-500">Loading interviews...</p>
        ) : interviews.length > 0 ? (
          <div className="space-y-4">
            {interviews.map(interview => (
              <InterviewCard 
                key={interview.id}
                interview={interview}
                onJoin={handleJoinInterview}
                onEvaluate={handleEvaluate}
              />
            ))}
          </div>
        ) : (
          <p className="text-center py-4 text-gray-500">
            No {activeTab} interviews found.
          </p>
        )}
      </>
    );
  };

  return (
    <div className="container mx-auto px-4 py-6 bg-gray-50 min-h-screen">
      <h1 className="text-2xl font-semibold text-gray-800 mb-6">Virtual Interview Dashboard</h1>
      
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2">
          <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
            <div className="flex border-b mb-4">
              {tabs.map(tab => (
                <button
                  key={tab.id}
                  className={`py-2 px-4 font-medium border-b-2 transition-colors duration-200 ${
                    activeTab === tab.id 
                      ? 'text-blue-600 border-blue-600' 
                      : 'text-gray-500 border-transparent hover:text-blue-500'
                  }`}
                  onClick={() => setActiveTab(tab.id)}
                >
                  {tab.label}
                </button>
              ))}
            </div>

            {renderContent()}
          </div>
        </div>

        <div className="space-y-6">
          <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
            <h2 className="text-lg font-medium text-gray-800 mb-4">Quick Actions</h2>
            <Link 
              to="/virtual-interview/scheduling" 
              className="block bg-blue-500 hover:bg-blue-600 text-white text-sm font-medium py-3 px-4 rounded-md mb-3 text-center transition-colors duration-300"
            >
              <FaCalendarAlt className="inline-block mr-2" />
              Schedule New Interview
            </Link>
            <Link 
              to="/analytics" 
              className="block bg-green-500 hover:bg-green-600 text-white text-sm font-medium py-3 px-4 rounded-md text-center transition-colors duration-300"
            >
              <FaChartLine className="inline-block mr-2" />
              View Analytics
            </Link>
          </div>

          <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
            <h2 className="text-lg font-medium text-gray-800 mb-4">Interview Statistics</h2>
            <InterviewStats stats={stats} />
          </div>
        </div>
      </div>
    </div>
  );
}));

export default VirtualInterviewWorkflow;