import React, { useState, useEffect,useRef } from 'react';
import { observer, inject } from 'mobx-react';
import { 
  DuplicateIcon, 
  PlusIcon, 
  TrashIcon, 
  PencilIcon,
  CheckIcon,
  
  XIcon,
  SaveIcon
} from '@heroicons/react/outline';
import { 
    FaBriefcase, FaUsers, FaCalendarAlt, FaEnvelope, 
    FaClock, FaCheckCircle, FaTimes, FaSpinner, FaEdit ,FaSave
  } from 'react-icons/fa';
const EditableOutput = inject('store')(observer(({ 
  store, 
  title, 
  desc, 
  Icon, 
  output, 
  loading, 
  fromColor, 
  toColor,
  selectedJobId,
  onUpdate 
}) => {
  const [editableData, setEditableData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [newItemText, setNewItemText] = useState('');
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveStatus, setSaveStatus] = useState(null);
  const [notification, setNotification] = useState({
    show: false,
    type: 'success',
    message: ''
  });

  const newQuestionRef = useRef(null);

  useEffect(() => {
    if (isAddingNew && newQuestionRef.current) {
      newQuestionRef.current.focus();
    }
  }, [isAddingNew]);


  // Parse the JSON string into editableData
  useEffect(() => {
    if (output) {
      try {
        const parsed = JSON.parse(output);
        const questionsArray = Object.entries(parsed).map(([key, value]) => ({
          questionNumber: key,
          question: value
        }));
        setEditableData(questionsArray);
      } catch (error) {
        console.error(`Error parsing JSON data: ${error}`);
        setEditableData([]);
      }
    }
  }, [output]);

  const handleEdit = (index) => {
    setEditingIndex(index);
  };

  const handleSave = (index) => {
    setEditingIndex(null);
  };

  const handleDelete = (index) => {
    const newData = editableData.filter((_, idx) => idx !== index);
    // Reorder question numbers after deletion
    const reorderedData = newData.map((item, idx) => ({
      ...item,
      questionNumber: `Question ${idx + 1}`
    }));
    setEditableData(reorderedData);
  };

  const handleChange = (index, newValue) => {
    const newData = [...editableData];
    newData[index].question = newValue;
    setEditableData(newData);
  };

  const showNotification = (type, message) => {
    setNotification({ show: true, type, message });
  };
  const handleAddNew = () => {
    if (newItemText.trim()) {
      const newQuestionNumber = `Question ${editableData.length + 1}`;
      setEditableData([
        ...editableData,
        {
          questionNumber: newQuestionNumber,
          question: newItemText.trim()
        }
      ]);
      setNewItemText('');
      setIsAddingNew(false);
    }
  };

  const handleSaveToDatabase = async () => {
    if (!selectedJobId) {
      showNotification('error', 'No job selected');
      return;
    }

    setIsSaving(true);
    setSaveStatus(null);

    try {
      // Convert editableData back to the required JSON string format
      const formattedQuestions = editableData.reduce((acc, item) => {
        acc[item.questionNumber] = item.question;
        return acc;
      }, {});

      const response = await store.api.post('/user/JobDetails/updateQuestions', {
        jobId: selectedJobId,
        interviewQuestions: JSON.stringify(formattedQuestions)
      });

      if (response.data.success) {
        setSaveStatus('success');
        showNotification('success', 'Questions saved successfully');

        if (onUpdate) {
            onUpdate();
          }
  
      } else {
        setSaveStatus('error');
        showNotification('error', 'Failed to save questions');
      }
    } catch (error) {
      console.error('Error saving questions:', error);
      setSaveStatus('error');
      showNotification('error', 'Error saving questions');
    } finally {
      setIsSaving(false);
      setTimeout(() => setSaveStatus(null), 3000);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden mb-8">
        <Notification
      show={notification.show}
      type={notification.type}
      message={notification.message}
      onClose={() => setNotification(prev => ({ ...prev, show: false }))}
    />

      <div className={`bg-gradient-to-r from-${fromColor || 'blue-500'} to-${toColor || 'indigo-600'} px-6 py-4`}>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {Icon && <Icon className="h-6 w-6 text-white mr-3" aria-hidden="true" />}
            <div>
              <h3 className="text-lg font-semibold text-white">{title}</h3>
              <p className="text-sm text-blue-100">{desc}</p>
            </div>
          </div>
          <div className="flex items-center space-x-2">
          <button
  onClick={handleSaveToDatabase}
  disabled={isSaving || loading}
  className={`text-white hover:bg-white/20 rounded-full p-1.5 transition-colors duration-200 
    ${isSaving ? 'opacity-50 cursor-not-allowed' : ''} 
    ${saveStatus === 'success' ? 'bg-green-500' : ''} 
    ${saveStatus === 'error' ? 'bg-red-500' : ''}`}
  title="Save changes"
>
  {isSaving ? (
    <div className="animate-spin rounded-full h-5 w-5 border-2 border-white border-t-transparent" />
  ) : (
    <FaSave className="w-5 h-5" />
  )}
</button>

            <button
              onClick={() => setIsAddingNew(true)}
              disabled={isSaving || loading}
              className="text-white hover:bg-white/20 rounded-full p-1.5 transition-colors duration-200"
              title="Add new question"
            >
              <PlusIcon className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>

      <div className="px-6 py-4">
        {loading ? (
          <div className="flex justify-center items-center h-32">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
          </div>
        ) : (
          <div className="space-y-4">
            {editableData.map((item, index) => (
              <div key={item.questionNumber} className="group relative flex items-start space-x-3 py-3 border-b border-gray-200 last:border-b-0">
                <div className="flex-shrink-0 w-24">
                  <span className="text-sm font-medium text-gray-500">
                    {item.questionNumber}:
                  </span>
                </div>
                <div className="flex-1">
                  {editingIndex === index ? (
                    <textarea
                      value={item.question}
                      onChange={(e) => handleChange(index, e.target.value)}
                      className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      rows="3"
                    />
                  ) : (
                    <p className="text-sm text-gray-900">{item.question}</p>
                  )}
                </div>
                <div className="flex items-center space-x-2 opacity-0 group-hover:opacity-100 transition-opacity">
                  {editingIndex === index ? (
                    <button
                      onClick={() => handleSave(index)}
                      className="text-green-600 hover:text-green-700 transition-colors duration-200"
                      title="Save"
                    >
                      <CheckIcon className="w-5 h-5" />
                    </button>
                  ) : (
                    <button
                      onClick={() => handleEdit(index)}
                      className="text-blue-600 hover:text-blue-700 transition-colors duration-200"
                      title="Edit"
                    >
                      <PencilIcon className="w-5 h-5" />
                    </button>
                  )}
                  <button
                    onClick={() => handleDelete(index)}
                    className="text-red-600 hover:text-red-700 transition-colors duration-200"
                    title="Delete"
                  >
                    <TrashIcon className="w-5 h-5" />
                  </button>
                </div>
              </div>
            ))}

            {isAddingNew && (
              <div className="border-t pt-4">
                <textarea
                  value={newItemText}
                  ref={newQuestionRef}

                  onChange={(e) => setNewItemText(e.target.value)}
                  placeholder="Enter new question..."
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  rows="3"
                />
                <div className="flex justify-end space-x-2 mt-2">
                  <button
                    onClick={() => setIsAddingNew(false)}
                    className="px-3 py-1.5 text-sm text-gray-600 hover:text-gray-700 transition-colors duration-200"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleAddNew}
                    className="px-3 py-1.5 text-sm bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200"
                    disabled={!newItemText.trim()}
                  >
                    Add Question
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <QuickTools store={store} output={output} editableData={editableData} />
    </div>
  );
}));

const Notification = ({ show, type = 'success', message, onClose }) => {
    useEffect(() => {
      if (show) {
        const timer = setTimeout(onClose, 5000);
        return () => clearTimeout(timer);
      }
    }, [show, onClose]);
  
    if (!show) return null;
  
    const bgColor = type === 'success' ? 'bg-green-50 border-green-200' : 'bg-red-50 border-red-200';
    const textColor = type === 'success' ? 'text-green-800' : 'text-red-800';
    const icon = type === 'success' ? <FaCheckCircle className="h-5 w-5" /> : <FaTimes className="h-5 w-5" />;
  
    return (
      <div className="fixed top-4 right-4 z-50 w-96 transform transition-all">
        <div className={`${bgColor} ${textColor} p-4 rounded-lg border shadow-lg`}>
          <div className="flex items-start">
            <div className="flex-shrink-0">{icon}</div>
            <div className="ml-3 w-full">
              <p className="font-medium">{type === 'success' ? 'Success' : 'Error'}</p>
              <p className="mt-1 text-sm opacity-90">{message}</p>
            </div>
            <button onClick={onClose} className="ml-4">
              <FaTimes className="h-4 w-4" />
            </button>
          </div>
        </div>
      </div>
    );
  };
const QuickTools = inject('store')(observer(({ store, output, editableData }) => {
  const handleCopy = () => {
    const formattedData = editableData.reduce((acc, item) => {
      acc[item.questionNumber] = item.question;
      return acc;
    }, {});
    store.copyToClipboard(JSON.stringify(formattedData, null, 2));
  };

  if (!output) return null;

  return (
    <div className="bg-gray-50 px-6 py-3 flex justify-end space-x-2">
      <button
        onClick={handleCopy}
        className="text-gray-600 hover:text-blue-600 transition-colors duration-200"
        title="Copy to clipboard"
      >
        <DuplicateIcon className="w-5 h-5" />
      </button>
    </div>
  );
}));

export default EditableOutput;