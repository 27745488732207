import React, { useState, useRef, useEffect } from 'react';
import { Room, createLocalTracks } from 'livekit-client';
import { BsMicFill, BsMicMuteFill, BsCameraVideoFill, BsCameraVideoOffFill } from 'react-icons/bs';
import { FaUserTie, FaBriefcase, FaCalendarAlt, FaCheckCircle } from 'react-icons/fa';


export const PreInterviewScreen = ({ 
  interviewDetails, 
  onStart, 
  className = '' 
}) => {
  const [isMicEnabled, setIsMicEnabled] = useState(true);
  const [isCameraEnabled, setIsCameraEnabled] = useState(true);
  const [devices, setDevices] = useState({ audio: [], video: [] });
  const [selectedAudioDevice, setSelectedAudioDevice] = useState('');
  const [selectedVideoDevice, setSelectedVideoDevice] = useState('');
  const [localTracks, setLocalTracks] = useState({ audio: null, video: null });
  const videoPreviewRef = useRef(null);

  // Fetch available devices
  useEffect(() => {
    const getDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        setDevices({
          audio: devices.filter(device => device.kind === 'audioinput'),
          video: devices.filter(device => device.kind === 'videoinput')
        });
        
        // Set default devices
        const defaultAudio = devices.find(d => d.kind === 'audioinput');
        const defaultVideo = devices.find(d => d.kind === 'videoinput');
        if (defaultAudio) setSelectedAudioDevice(defaultAudio.deviceId);
        if (defaultVideo) setSelectedVideoDevice(defaultVideo.deviceId);
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    };

    getDevices();
  }, []);

  // Initialize and manage local tracks
  useEffect(() => {
    const initializeTracks = async () => {
      try {
        const tracks = await createLocalTracks({
          audio: isMicEnabled ? { deviceId: selectedAudioDevice } : false,
          video: isCameraEnabled ? { deviceId: selectedVideoDevice } : false,
        });

        const audioTrack = tracks.find(track => track.kind === 'audio');
        const videoTrack = tracks.find(track => track.kind === 'video');

        setLocalTracks({ audio: audioTrack, video: videoTrack });

        if (videoTrack && videoPreviewRef.current) {
          videoTrack.attach(videoPreviewRef.current);
        }

        return () => {
          tracks.forEach(track => track.stop());
        };
      } catch (error) {
        console.error('Error creating local tracks:', error);
      }
    };

    initializeTracks();
  }, [isMicEnabled, isCameraEnabled, selectedAudioDevice, selectedVideoDevice]);

  const handleDeviceChange = async (type, deviceId) => {
    if (type === 'audio') {
      setSelectedAudioDevice(deviceId);
      if (localTracks.audio) {
        await localTracks.audio.stop();
      }
    } else if (type === 'video') {
      setSelectedVideoDevice(deviceId);
      if (localTracks.video) {
        await localTracks.video.stop();
      }
    }
  };

  const handleStart = () => {
    // Pass the selected devices and settings to the parent
    onStart({
      audioEnabled: isMicEnabled,
      videoEnabled: isCameraEnabled,
      audioDeviceId: selectedAudioDevice,
      videoDeviceId: selectedVideoDevice
    });
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 p-6">
      <div className="max-w-6xl mx-auto">
        {/* Header */}
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-gray-800 mb-2">
            Virtual Interview Setup
          </h1>
          <p className="text-gray-600">
            Please configure your devices and review the guidelines before starting
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Left Column - Interview Details */}
          <div className="bg-white rounded-xl shadow-md p-6 h-fit">
            <h2 className="text-xl font-semibold text-gray-800 mb-6 flex items-center">
              <span className="bg-blue-100 p-2 rounded-lg mr-3">
                <FaUserTie className="text-blue-600 text-xl" />
              </span>
              Interview Details
            </h2>
            
            <div className="space-y-4">
              <div className="flex items-center text-gray-700">
                <FaUserTie className="mr-3 text-blue-500" />
                <div>
                  <p className="text-sm text-gray-500">Candidate Name</p>
                  <p className="font-medium">{interviewDetails?.candidateName}</p>
                </div>
              </div>
              
              <div className="flex items-center text-gray-700">
                <FaBriefcase className="mr-3 text-blue-500" />
                <div>
                  <p className="text-sm text-gray-500">Position</p>
                  <p className="font-medium">{interviewDetails?.jobName}</p>
                </div>
              </div>
              
              <div className="flex items-center text-gray-700">
                <FaCalendarAlt className="mr-3 text-blue-500" />
                <div>
                  <p className="text-sm text-gray-500">Date & Time</p>
                  <p className="font-medium">
                    {new Date(interviewDetails?.interviewDate).toLocaleString()}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Middle Column - Device Settings */}
          <div className="bg-white rounded-xl shadow-md p-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-6 flex items-center">
              <span className="bg-blue-100 p-2 rounded-lg mr-3">
                <BsCameraVideoFill className="text-blue-600 text-xl" />
              </span>
              Device Settings
            </h2>

            {/* Video Preview */}
            <div className="mb-6">
              <div className="relative w-64 h-48 bg-gray-900 rounded-lg overflow-hidden mx-auto mb-4">
                {isCameraEnabled ? (
                  <video 
                    ref={videoPreviewRef}
                    autoPlay 
                    playsInline 
                    muted 
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center bg-gray-800 text-white">
                    <BsCameraVideoOffFill className="text-4xl" />
                  </div>
                )}
              </div>

              {/* Device Controls */}
              <div className="space-y-4">
                {/* Microphone Control */}
                <div className="bg-gray-50 p-4 rounded-lg">
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center">
                      {isMicEnabled ? 
                        <BsMicFill className="text-green-500 mr-2" /> : 
                        <BsMicMuteFill className="text-red-500 mr-2" />
                      }
                      <span className="font-medium">Microphone</span>
                    </div>
                    <button
                      onClick={() => setIsMicEnabled(!isMicEnabled)}
                      className={`px-4 py-1 rounded-full text-sm font-medium transition-colors ${
                        isMicEnabled 
                          ? 'bg-green-100 text-green-600 hover:bg-green-200' 
                          : 'bg-red-100 text-red-600 hover:bg-red-200'
                      }`}
                    >
                      {isMicEnabled ? 'Enabled' : 'Disabled'}
                    </button>
                  </div>
                  <select
                    className="w-full p-2 border rounded-lg bg-white"
                    value={selectedAudioDevice}
                    onChange={(e) => handleDeviceChange('audio', e.target.value)}
                    disabled={!isMicEnabled}
                  >
                    {devices.audio.map(device => (
                      <option key={device.deviceId} value={device.deviceId}>
                        {device.label || `Microphone ${devices.audio.indexOf(device) + 1}`}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Camera Control */}
                <div className="bg-gray-50 p-4 rounded-lg">
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center">
                      {isCameraEnabled ? 
                        <BsCameraVideoFill className="text-green-500 mr-2" /> : 
                        <BsCameraVideoOffFill className="text-red-500 mr-2" />
                      }
                      <span className="font-medium">Camera</span>
                    </div>
                    <button
                      onClick={() => setIsCameraEnabled(!isCameraEnabled)}
                      className={`px-4 py-1 rounded-full text-sm font-medium transition-colors ${
                        isCameraEnabled 
                          ? 'bg-green-100 text-green-600 hover:bg-green-200' 
                          : 'bg-red-100 text-red-600 hover:bg-red-200'
                      }`}
                    >
                      {isCameraEnabled ? 'Enabled' : 'Disabled'}
                    </button>
                  </div>
                  <select
                    className="w-full p-2 border rounded-lg bg-white"
                    value={selectedVideoDevice}
                    onChange={(e) => handleDeviceChange('video', e.target.value)}
                    disabled={!isCameraEnabled}
                  >
                    {devices.video.map(device => (
                      <option key={device.deviceId} value={device.deviceId}>
                        {device.label || `Camera ${devices.video.indexOf(device) + 1}`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column - Guidelines */}
          <div className="bg-white rounded-xl shadow-md p-6 h-fit">
            <h2 className="text-xl font-semibold text-gray-800 mb-6 flex items-center">
              <span className="bg-blue-100 p-2 rounded-lg mr-3">
                <FaCheckCircle className="text-blue-600 text-xl" />
              </span>
              Guidelines
            </h2>
            
            <ul className="space-y-4">
              {[
                'Ensure you are in a quiet environment',
                'Check your internet connection',
                'Test your microphone and camera',
                'Be prepared to answer questions',
                'Have your resume handy',
                'Feel free to ask questions'
              ].map((guideline, index) => (
                <li key={index} className="flex items-start">
                  <FaCheckCircle className="text-green-500 mt-1 mr-3" />
                  <span className="text-gray-700">{guideline}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Start Button */}
        <div className="mt-8 text-center">
          <button 
            onClick={handleStart}
            className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white font-bold py-3 px-8 rounded-lg hover:from-blue-600 hover:to-indigo-700 transform transition-all duration-200 hover:scale-105 shadow-lg"
          >
            Start Interview
          </button>
          <p className="mt-2 text-gray-600 text-sm">
            Make sure to test your devices before starting
          </p>
        </div>
      </div>
    </div>
  );
};
