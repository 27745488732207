import React, { Component } from 'react';
import { NavLink, Switch, Route, withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { observable, makeObservable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { motion } from 'framer-motion';
import { LockClosedIcon, UserAddIcon } from '@heroicons/react/solid';
import Logon from '../Login/Logon';
import Signup from '../Login/Signup';

@inject('store')
@observer
class Login extends Component {
  @observable email = ""
  @observable password = ""
  @observable fname = ""
  @observable lname = ""
  @observable errorMessage = ""
  @observable isSigningUp = false

  constructor() {
    super()
    makeObservable(this)
  }

  onChangeAny = (val, attr) => {
    this[attr] = val
    this.errorMessage = ""
  }

  onLogin = async (e) => {
    try {
      e.preventDefault()
      const { data } = await this.props.store.api.post('/auth/signin', {
        email: this.email,
        password: this.password,
      });

      // Check if user is only a candidate
      if (data.profile.permissions && 
          data.profile.permissions.length === 1 && 
          data.profile.permissions.includes('candidate')) {
        this.errorMessage = "This login is for recruiters only. Candidates should use the interview-specific login link sent to their email.";
        return;
      }

      // Proceed with login for non-candidate users
      this.props.store.loginWithDataTokenAndProfile(data);
    } catch (err) {
      console.error('Login error:', err);
      if (err?.response?.data?.message) {
        this.errorMessage = err.response.data.message;
      } else {
        this.errorMessage = "An error occurred during login. Please try again.";
      }
    }
  }

  onSignup = async (e) => {
    try {
      e.preventDefault()
      this.isSigningUp = true
      const { data } = await this.props.store.api.post('/auth/signup', {
        email: this.email,
        password: this.password,
        fname: this.fname,
        lname: this.lname
      });
      this.errorMessage = "Email sent to your email id for confirmation.";
    } catch (err) {
      console.error('Signup error:', err);
      if (err?.response?.data?.message) {
        this.errorMessage = err.response.data.message;
      } else {
        this.errorMessage = "An error occurred during signup. Please try again.";
      }
    } finally {
      this.isSigningUp = false;
    }
  }

  render() {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-600 via-blue-700 to-indigo-800 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <Helmet>
          <title>Login - Eigentic</title>
        </Helmet>

        <motion.div 
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="sm:mx-auto sm:w-full sm:max-w-md"
        >
          <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
            Welcome to Eigentic
          </h2>
          <p className="mt-2 text-center text-sm text-blue-200">
            Revolutionizing recruitment with AI
          </p>
        </motion.div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <motion.div 
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10"
          >
            <div className="flex justify-center space-x-4 mb-6">
              <NavLink
                to="/login"
                className={`flex items-center px-4 py-2 rounded-md ${
                  this.props.location.pathname === "/login"
                    ? "bg-blue-600 text-white"
                    : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                } transition duration-300 ease-in-out`}
              >
                <LockClosedIcon className="h-5 w-5 mr-2" />
                Login
              </NavLink>
              <NavLink
                to="/signup"
                className={`flex items-center px-4 py-2 rounded-md ${
                  this.props.location.pathname === "/signup"
                    ? "bg-blue-600 text-white"
                    : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                } transition duration-300 ease-in-out`}
              >
                <UserAddIcon className="h-5 w-5 mr-2" />
                Signup
              </NavLink>
            </div>

            <Switch>
              <Route path="/login">
                <Logon
                  landingPageUrl={this.props.store.landingPageUrl}
                  email={this.email}
                  password={this.password}
                  onChange={this.onChangeAny}
                  onLogin={this.onLogin}
                />
              </Route>
              <Route path="/signup">
                <Signup
                  email={this.email}
                  password={this.password}
                  fname={this.fname}
                  lname={this.lname}
                  onChange={this.onChangeAny}
                  onSignup={this.onSignup}
                  isSigningUp={this.isSigningUp}
                />
              </Route>
            </Switch>

            {this.errorMessage && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className={`mt-4 text-center text-sm p-3 rounded-md border ${
                  this.errorMessage.includes("Email sent")
                    ? "text-green-600 bg-green-100 border-green-200"
                    : "text-red-600 bg-red-100 border-red-200"
                }`}
              >
                {this.errorMessage}
                {this.errorMessage.includes("Candidates should") && (
                  <p className="mt-2 text-gray-600">
                    Please check your email for the interview-specific login link.
                  </p>
                )}
              </motion.div>
            )}

            <div className="mt-6 text-center">
              <p className="text-sm text-gray-600">
                For technical support, please contact our help desk.
              </p>
            </div>
          </motion.div>
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="mt-8 text-center text-sm text-blue-200"
        >
          &copy; Eigentica Soft solution Pvt Ltd.
        </motion.div>
      </div>
    );
  }
}

export default withRouter(Login);