import React from 'react';
import { Link } from 'react-router-dom';
import { FaBriefcase, FaSearch, FaUsers, FaClipboardCheck, FaArrowRight } from 'react-icons/fa';

const WorkflowGuide = () => {
  const steps = [
    {
      id: 1,
      title: 'Job Description',
      description: 'Add/Edit Job description.',
      icon: FaBriefcase,
      color: 'bg-blue-500',
      link: '/manage-jobs'
    },
    {
      id: 2,
      title: 'Screening',
      description: 'Upload and analyze resumes for the selected job.',
      icon: FaSearch,
      color: 'bg-green-500',
      link: '/screening'
    },
    {
      id: 3,
      title: 'View analyzed canidate list',
      description: 'View and manage analyzed candidate details.',
      icon: FaUsers,
      color: 'bg-yellow-500',
      link: '/candidate-details'
    },
    {
      id: 4,
      title: 'Configure interview questions',
      description: 'Generate interview question or configure your own.',
      icon: FaClipboardCheck,
      color: 'bg-purple-500',
      link: '/assessment'
    },
    {
      id: 5,
      title: 'AI Interviewer',
      description: 'Schedule interview with AI interviewer.',
      icon: FaClipboardCheck,
      color: 'bg-pink-500',
      link: '/virtual-interview'
    }
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-gray-800 mb-8 text-center">Application Workflow Guide</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {steps.map((step, index) => (
          <div key={step.id} className="relative">
            <Link 
              to={step.link}
              className={`block ${step.color} rounded-lg p-6 shadow-lg hover:shadow-xl transition-shadow duration-300 h-full`}
            >
              <div className="flex items-center justify-between mb-4">
                <step.icon className="text-white text-3xl" />
                <span className="text-white text-2xl font-bold">{step.id}</span>
              </div>
              <h2 className="text-white text-xl font-semibold mb-2">{step.title}</h2>
              <p className="text-white text-sm">{step.description}</p>
            </Link>
            {index < steps.length - 1 && (
              <FaArrowRight className="absolute top-1/2 -right-4 text-gray-400 transform -translate-y-1/2 hidden lg:block" />
            )}
          </div>
        ))}
      </div>
      <div className="mt-12 text-center">
        <p className="text-gray-600 mb-4">Follow these steps to efficiently manage your recruitment process.</p>
        <Link 
          to="/manage-jobs" 
          className="inline-block bg-indigo-600 text-white font-semibold px-6 py-3 rounded-full hover:bg-indigo-700 transition-colors duration-300"
        >
          Start Your Workflow
        </Link>
      </div>
    </div>
  );
};

export default WorkflowGuide;