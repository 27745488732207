import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import { Dropdown } from 'primereact/dropdown';
import { observer, inject } from 'mobx-react';
import Layout from '../Layout/Layout';
import EditableOutput from '../EditableOutput';
import TextOutputTile from '../TextOutputTile';
import Logo from '../../Logo';
import { LightBulbIcon } from '@heroicons/react/outline';

const Assessment = ({ store }) => {
    const [selectedJobId, setSelectedJobId] = useState(null);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [jobDescription, setJobDescription] = useState(null);

    useEffect(() => {
        refreshJobList();
    }, []);

    const refreshJobList = async () => {
        let response = await store.api.post('/user/JobDetails/view');
        store.joblist = response.data.map((job) => ({
            jobid: job.jobId,
            jobname: job.jobname,
            jobdescription: job.jobdescription,
            interviewQuestions: job.interviewQuestions
        }));
    };

    const refreshJobData = async () => {
        if (selectedJobId) {
            setLoading(true);
            try {
                const selectedJob = store.joblist.find((job) => job.jobid === selectedJobId);
                if (selectedJob) {
                    // Fetch fresh data for the selected job
                    const response = await store.api.post('/user/JobDetails/view');
                    const updatedJobs = response.data;
                    const updatedJob = updatedJobs.find(job => job.jobId === selectedJobId);
                    
                    if (updatedJob) {
                        setJobDescription(updatedJob.jobdescription);
                        setData(updatedJob.interviewQuestions);
                        
                        // Update store.joblist
                        store.joblist = updatedJobs.map((job) => ({
                            jobid: job.jobId,
                            jobname: job.jobname,
                            jobdescription: job.jobdescription,
                            interviewQuestions: job.interviewQuestions
                        }));
                    }
                }
            } catch (error) {
                console.error('Error refreshing job data:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleJobIdChange = async (e) => {
        setSelectedJobId(e.value);
        const selectedJob = store.joblist.find((job) => job.jobid === e.value);
        if (selectedJob) {
            setJobDescription(selectedJob.jobdescription);
            setData(selectedJob.interviewQuestions);
        }
    };

    const generateAssessment = async () => {
        setLoading(true);
        try {
            const assessmentResponse = await store.api.post('/ai/assessment', {
                jobid: selectedJobId,
                description: jobDescription,
            });
            setData(assessmentResponse.data.output);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout>
            <div className="bg-gradient-to-br from-blue-50 to-indigo-100 min-h-screen">
                <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                    {/* Add the breadcrumb here */}
                    <div className="mb-6">
                        <Link to="/" className="text-blue-600 hover:text-blue-800 flex items-center">
                            <FaChevronLeft className="mr-2" />
                            Back to Workflow
                        </Link>
                    </div>

                    <div className="max-w-3xl mx-auto">
                        <h1 className="text-3lg font-bold text-gray-900 mb-8">Generate Interview Questions</h1>
                        <div className="bg-white shadow-lg rounded-xl overflow-hidden mb-8">
                            <div className="px-6 py-8">
                                <label htmlFor="jobId" className="block text-sm font-medium text-gray-700 mb-2">
                                    Select Job
                                </label>
                                <div className="flex items-center space-x-4">
                                    <Dropdown
                                        id="jobId"
                                        value={selectedJobId}
                                        options={store.joblist.map((job) => ({ value: job.jobid, label: job.jobname }))}
                                        onChange={handleJobIdChange}
                                        placeholder="Choose a job"
                                        className="w-3/5 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                    <button
                                        onClick={generateAssessment}
                                        disabled={!selectedJobId || loading}
                                        className={`flex items-center justify-center px-6 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-indigo-600 to-blue-600 hover:from-indigo-700 hover:to-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    >
                                        {loading ? (
                                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                        ) : (
                                            <LightBulbIcon className="w-5 h-5 mr-2" />
                                        )}
                                        Generate assessment
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {(jobDescription || data) && (
                        <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2">
                            <TextOutputTile 
                                title="Job Description"
                                desc="Details of the selected job"
                                Icon={Logo}
                                fromColor=""
                                loading={loading}
                                output={jobDescription}
                            />
                            <EditableOutput 
                                title="Interview Questions"
                                desc="Generated questions based on the job description"
                                Icon={Logo}
                                fromColor="blue-300"
                                toColor="blue-400"
                                loading={loading}
                                output={data}
                                selectedJobId={selectedJobId}
                                onUpdate={refreshJobData}  

                            />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default inject('store')(observer(Assessment));