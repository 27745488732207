// EmailTemplateManager.js
import React, { useState, useEffect } from 'react';
import { FaEdit, FaEye, FaSave, FaTimes, FaUndo } from 'react-icons/fa';

const defaultEmailTemplate = `
<!DOCTYPE html>
<html>
<head>
  <style>
    body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
    .container { max-width: 600px; margin: 0 auto; padding: 20px; }
    .header { background-color: #f8f9fa; padding: 20px; text-align: center; border-radius: 5px; }
    .content { padding: 20px; }
    .details { background-color: #f8f9fa; padding: 15px; margin: 20px 0; border-radius: 5px; }
    .button { 
      display: inline-block; 
      padding: 12px 24px; 
      background-color: #007bff; 
      color: white !important; 
      text-decoration: none; 
      border-radius: 5px; 
      margin: 20px 0;
      font-weight: 500;
    }
    .button:hover {
      background-color: #0056b3;
    }
    .important-note {
      background-color: #fff3cd;
      border: 1px solid #ffeeba;
      color: #856404;
      padding: 15px;
      border-radius: 5px;
      margin: 15px 0;
    }
    .credentials {
      background-color: #e9ecef;
      padding: 15px;
      margin: 20px 0;
      border-radius: 5px;
      border-left: 4px solid #007bff;
    }
    ul { padding-left: 20px; }
    li { margin-bottom: 8px; }
  </style>
</head>
<body>
  <div class="container">
    <div class="header">
      <h1>Interview Invitation</h1>
    </div>
    <div class="content">
      <p>Dear {{candidateName}},</p>
      
      <p>Thank you for your interest in the <strong>{{jobName}}</strong> position. We are pleased to invite you for an interview.</p>
      
      <div class="credentials">
        <h3>Your Login Credentials:</h3>
        <p><strong>Email:</strong> {{email}}</p>
        <p><strong>Password:</strong> {{password}}</p>
        <p class="important-note">
          <strong>Important:</strong> Please keep these credentials secure. 
          You'll need them to access the interview room.
        </p>
      </div>

      <div class="details">
        <h3>Interview Details:</h3>
        <p><strong>Date:</strong> {{interviewDate}}</p>
        <p><strong>Time:</strong> {{interviewTime}}</p>
        <p><strong>Format:</strong> Virtual Interview</p>
      </div>

      <div style="text-align: center;">
        <a href="{{interviewLink}}" class="button">Join Interview</a>
      </div>

      <div class="details">
        <h3>Position Overview:</h3>
        <p>{{jobDescription}}</p>
      </div>

      

      <p>If you need to reschedule or have any technical difficulties, please contact our support team.</p>

      <p>Best regards,<br>Eigentic Team</p>
    </div>
  </div>
</body>
</html>
`;

const Button = ({ onClick, children, variant = 'primary', className = '', disabled = false }) => {
  const baseStyles = 'px-4 py-2 rounded-lg font-medium transition-all duration-200 ease-in-out flex items-center justify-center';
  const variants = {
    primary: 'bg-blue-600 text-white hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
    secondary: 'bg-gray-200 text-gray-800 hover:bg-gray-300 focus:ring-2 focus:ring-gray-500 focus:ring-offset-2',
    danger: 'bg-red-600 text-white hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-offset-2'
  };

  return (
    <button 
      onClick={onClick}
      disabled={disabled}
      className={`${baseStyles} ${variants[variant]} ${className} ${
        disabled ? 'opacity-50 cursor-not-allowed' : ''
      }`}
    >
      {children}
    </button>
  );
};

const EmailTemplateEditor = ({
  initialTemplate = defaultEmailTemplate,
  initialSubject = '',
  variables = {},
  onSave,
  onClose
}) => {
  const [emailSubject, setEmailSubject] = useState(initialSubject);
  const [emailBody, setEmailBody] = useState(initialTemplate);
  const [showPreview, setShowPreview] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  // Reset to initial values
  const handleReset = () => {
    setEmailSubject(initialSubject);
    setEmailBody(initialTemplate);
    setHasChanges(false);
  };

  // Update change tracking
  useEffect(() => {
    const hasSubjectChanged = emailSubject !== initialSubject;
    const hasBodyChanged = emailBody !== initialTemplate;
    setHasChanges(hasSubjectChanged || hasBodyChanged);
  }, [emailSubject, emailBody, initialSubject, initialTemplate]);

  // Replace template variables with actual values
  const getPreviewContent = () => {
    let preview = emailBody;
    Object.entries(variables).forEach(([key, value]) => {
      const regex = new RegExp(`{{${key}}}`, 'g');
      preview = preview.replace(regex, value || `[${key}]`);
    });
    return preview;
  };

  // Handle save
  const handleSave = () => {
    onSave({
      subject: emailSubject,
      body: emailBody
    });
    setHasChanges(false);
  };

  return (
    <div className="space-y-4" style={{ position: 'relative', zIndex: 99999 }}>
      <div className="sticky top-0 bg-white z-50 pb-4 border-b border-gray-200 mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Email Subject
        </label>
        <input
          type="text"
          value={emailSubject}
          onChange={(e) => setEmailSubject(e.target.value)}
          className="w-full p-2 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
        />
        <div className="flex justify-end mt-4">
          <Button
            onClick={() => setShowPreview(!showPreview)}
            variant="secondary"
            className="text-sm"
          >
            {showPreview ? (
              <><FaEdit className="mr-2" /> Edit Template</>
            ) : (
              <><FaEye className="mr-2" /> Preview</>
            )}
          </Button>
        </div>
      </div>

      <div className="mt-4">
        {showPreview ? (
          <div 
            className="border rounded-lg p-4 bg-white overflow-y-auto"
            style={{ height: '500px' }}
            dangerouslySetInnerHTML={{ 
              __html: getPreviewContent()
            }}
          />
        ) : (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Email Template
            </label>
            <textarea
              value={emailBody}
              onChange={(e) => setEmailBody(e.target.value)}
              className="w-full p-2 border rounded-md shadow-sm font-mono text-sm focus:ring-blue-500 focus:border-blue-500"
              style={{ minHeight: '400px' }}
            />
            
          </div>
        )}
      </div>
    </div>
  );
};
;

// Usage example component
const EmailTemplateManager = ({
  jobName = '',
  candidateName = '',
  jobDescription = '',
  interviewDate = '',
  interviewTime = '',
  interviewLink = '',
  email = '',
  password = '',
  onSave,
  onClose
}) => {
  const variables = {
    jobName,
    candidateName,
    jobDescription,
    interviewDate,
    interviewTime,
    interviewLink,
    email,
    password
  };

  const handleSave = (template) => {
    onSave(template);
  };

  return (
    <EmailTemplateEditor
      initialSubject={`Interview Invitation for ${jobName || '[Position]'}`}
      initialTemplate={defaultEmailTemplate}
      variables={variables}
      onSave={handleSave}
      onClose={onClose}
    />
  );
};

export { EmailTemplateManager, defaultEmailTemplate };

const globalStyles = `
  .modal-overlay {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    z-index: 99999 !important;
    background: rgba(0, 0, 0, 0.5) !important;
  }

  .modal-container {
    position: relative !important;
    z-index: 100000 !important;
  }

  .modal-content {
    background: white !important;
    position: relative !important;
    z-index: 100001 !important;
  }
`;

// Add these styles to your document
const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = globalStyles;
document.head.appendChild(styleSheet);