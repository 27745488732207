import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { observable, makeObservable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { motion } from 'framer-motion';
import { LockClosedIcon, UserIcon } from '@heroicons/react/solid';

@inject('store')
@observer
class CandidateLogin extends Component {
  @observable email = ""
  @observable password = ""
  @observable errorMessage = ""
  @observable interviewLink = null
  @observable isLoading = false

  constructor(props) {
    super(props);
    makeObservable(this);
    
    // First check URL for interview link
    const path = window.location.pathname;
    const matches = path.match(/virtual-interview-room\/([^/?]+)/);
    if (matches && matches[1]) {
      this.interviewLink = matches[1];
      console.log('Found interview link in URL:', this.interviewLink);
      localStorage.setItem('pendingInterviewLink', this.interviewLink);
    } else {
      // If not in URL, check localStorage
      this.interviewLink = localStorage.getItem('pendingInterviewLink');
      console.log('Found interview link in localStorage:', this.interviewLink);
    }
  }

  componentDidMount() {
    // Pre-fill email if available
    const candidateEmail = localStorage.getItem('candidateEmail');
    if (candidateEmail) {
      this.email = candidateEmail;
    }
    console.log('Component mounted with interview link:', this.interviewLink);
  }

  @action
  onChangeAny = (val, attr) => {
    this[attr] = val;
    this.errorMessage = "";
  }

  @action
  onLogin = async (e) => {
    try {
      e.preventDefault();
      this.isLoading = true;
      this.errorMessage = "";

      console.log('Attempting login with interview link:', this.interviewLink);

      const response = await this.props.store.api.post('/auth/signin', {
        email: this.email,
        password: this.password,
      });

      const { data } = response;
      
      // Store the token and profile
      await this.props.store.loginWithDataTokenAndProfile(data);

      if (data.profile.accountType !== 'candidate') {
        this.errorMessage = "This login is only for interview candidates. Please use the main login page.";
        return;
      }

      // Get the interview link from instance variable or localStorage
      const interviewLink = this.interviewLink || localStorage.getItem('pendingInterviewLink');
      console.log('Retrieved interview link for redirect:', interviewLink);

      if (!interviewLink) {
        this.errorMessage = "Interview link not found. Please check your invitation email.";
        return;
      }

      // Clean up storage
      localStorage.removeItem('pendingInterviewLink');
      localStorage.removeItem('candidateEmail');
      
      // Redirect to interview room
      console.log('Redirecting to:', `/virtual-interview-room/${interviewLink}`);
      this.props.history.push(`/virtual-interview-room/${interviewLink}`);

    } catch (err) {
      console.error('Login error:', err);
      this.errorMessage = err?.response?.data?.message || 'Invalid credentials. Please check your email and password.';
    } finally {
      this.isLoading = false;
    }
  }

  render() {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-600 via-blue-700 to-indigo-800 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <Helmet>
          <title>Interview Login - Eigentic</title>
        </Helmet>

        <motion.div 
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="sm:mx-auto sm:w-full sm:max-w-md"
        >
          <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
            Welcome to Your Interview
          </h2>
          <p className="mt-2 text-center text-sm text-blue-200">
            Please log in with the credentials provided in your interview invitation
          </p>
        </motion.div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <motion.div 
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10"
          >
            <form className="space-y-6" onSubmit={this.onLogin}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1 relative">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    value={this.email}
                    onChange={(e) => this.onChangeAny(e.target.value, 'email')}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <UserIcon className="h-5 w-5 text-gray-400" />
                  </div>
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1 relative">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    value={this.password}
                    onChange={(e) => this.onChangeAny(e.target.value, 'password')}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <LockClosedIcon className="h-5 w-5 text-gray-400" />
                  </div>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={this.isLoading}
                  className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white 
                    ${this.isLoading ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'}`}
                >
                  {this.isLoading ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Logging in...
                    </>
                  ) : (
                    'Join Interview'
                  )}
                </button>
              </div>
            </form>

            {this.errorMessage && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="mt-4 text-center text-sm text-red-600 bg-red-100 p-3 rounded-md border border-red-200"
              >
                {this.errorMessage}
              </motion.div>
            )}

            <div className="mt-4 text-center text-sm text-gray-600">
              <p>Please check your email for login credentials.</p>
              <p className="mt-2">If you haven't received the credentials, please contact your interviewer.</p>
            </div>
          </motion.div>
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="mt-8 text-center text-sm text-blue-200"
        >
          &copy; Eigentica Soft solution Pvt Ltd.
        </motion.div>
      </div>
    );
  }
}

export default withRouter(CandidateLogin);